import { Outlet } from "react-router-dom";
import Navbar from "./Navbar"; // ⚠️ verify it's the correct path
import { useLocation, useNavigate } from 'react-router-dom'
import {UnviewedInvitations} from "../site_controller/atoms"
import {PlayMobile} from "../games/atoms/Common"
import {useRecoilState,} from 'recoil';
import { Snackbar, Alert, Button, Divider, Box} from '@mui/material';
import {FormattedMessage} from "react-intl";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {sendMsgSite} from "../site_controller/SiteController"
import {SiteWS,} from "../site_controller/atoms"

const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [playMobile, setPlayMobile] = useRecoilState(PlayMobile)
    const [unviewedInvitations, setUnviewedInvitations] = useRecoilState(UnviewedInvitations)
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);

    const sendViewedInvitation = (invitationID)=>{
      sendMsgSite(siteWS.ws, "ViewedInvitation", {InvitationID: invitationID})

      var newInvitations = []
      for (const invitation of unviewedInvitations.Invitations) {
        if (invitation.ID !== invitationID) {
          newInvitations.push(invitation)
        }        
      }
    //  setUnviewedInvitations({Invitations: newInvitations})
    }
    console.log("ASASAS LOCATION = ", location)
  return (
    <>
      <Navbar/>
      <Outlet />
      {location.pathname != "/" && location.pathname != "/leaderboards" && !location.pathname.startsWith("/user") && !location.pathname.startsWith("/game_page") && <Footer/>}

      {false && !playMobile && unviewedInvitations.Invitations.map((item, index) => (
      <Snackbar open={true}
        onClose={() => { }}
        autoHideDuration={60000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"unviewedInvitationsSnackbar"+index}>
          <Alert severity="info" sx={{ width: '100%' }} key={"GameInvitation"+index}
            action={
              <>
                <Button color="inherit" size="small" onClick={()=>{
                  navigate("/game_page/"+item.GameName+"/"+item.InvitingNickname)
                  }}>
                  <FormattedMessage id="accept" />
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    sendViewedInvitation(item.ID);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            }          
          >
            <FormattedMessage id="invited_you" />{item.InvitingNickname}<FormattedMessage id="to_play" />"<FormattedMessage id={item.GameName} />"
          </Alert>
			</Snackbar>
    ))}
    </>
  );
};


export default Layout;


const Footer = ()=>{
  return (
    <Box
      sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
      }}
    >
      <Divider style={{width: "100%"}}/>
      © {(new Date().getFullYear())} 
      <a href="https://vk.com/minicybersport"><img src="/auth/vk_auth.png" style={{width: "30px", paddingLeft: "10px", paddingTop: "10px"}}/></a>
      <a href="https://t.me/MiniCyberSportRu"><img src="/auth/tg_auth.png" style={{width: "30px", paddingLeft: "10px", paddingTop: "10px"}}/></a>
      <a href="/legal_information" style={{marginLeft: "10px",}}><FormattedMessage id="legal_information" /></a>
      <div><a href="https://audionautix.com/">Creative Commons Music by Jason Shaw on Audionautix.com</a></div>
    </Box>
  )
}