import { Button,   } from '@mui/material';
import { SingleEliminationBracket, DoubleEliminationBracket, Match, MATCH_STATES, SVGViewer, createTheme  } from '@g-loot/react-tournament-brackets';
import {FormattedMessage} from "react-intl";
import TournamentsService from "../services/tournaments/TournamentsService"
import React, { useState, useEffect } from 'react';
import { SuccessMessage } from '../constants';
import { useWindowSize } from "@uidotdev/usehooks";
import { useTheme } from '@emotion/react';
import {UserInfoAtom} from "../user/atoms/UserInfo"
import {linkToGameByID} from "../utils/Games"
import {useNavigate,} from "react-router-dom"
import {useRecoilState,} from 'recoil';
import {sendMsgSite} from "../site_controller/SiteController"
import {SiteWS} from "../site_controller/atoms"
import {BracketsMatches} from "./atoms"

const WhiteTheme = createTheme({
  textColor: { main: '#000000', highlighted: '#07090D', dark: '#3E414D' },
  matchBackground: { wonColor: '#daebf9', lostColor: '#96c6da' },
  score: {
    background: { wonColor: '#87b2c4', lostColor: '#87b2c4' },
    text: { highlightedWonColor: '#7BF59D', highlightedLostColor: '#FB7E94' },
  },
  border: {
    color: '#CED1F2',
    highlightedColor: '#da96c6',
  },
  roundHeader: { backgroundColor: '#da96c6', fontColor: '#000' },
  connectorColor: '#CED1F2',
  connectorColorHighlight: '#da96c6',
  svgBackground: '#FAFAFA',
});


const matchesTemplate = [
    {
      "id": 260005,
      "name": "Final - Match",
      "nextMatchId": null, // Id for the nextMatch in the bracket, if it's final match it must be null OR undefined
      "tournamentRoundText": "4", // Text for Round Header
      "startTime": "2021-05-30",
      "state": "DONE", // 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | 'DONE' | 'SCORE_DONE' Only needed to decide walkovers and if teamNames are TBD (to be decided)
      "participants": [
        {
          "id": "c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc", // Unique identifier of any kind
          "resultText": "WON", // Any string works
          "isWinner": true,
          "status": null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          "name": "giacomo123"
        },
        {
          "id": "9ea9ce1a-4794-4553-856c-9a3620c0531b",
          "resultText": null,
          "isWinner": false,
          "status": null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY'
          "name": "Ant"
        }
      ]
    }
  ]

const Brackets = (props) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const [matches, setMatches] = useState([])
  const [mePlaying, setMePlaying] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

  const [siteWS, setSiteWS]  = useRecoilState(SiteWS);
  const [bracketsMatches, setBracketsMatches]  = useRecoilState(BracketsMatches);

  const makeBracketsMatchesData = (matches)=>{
    var receivedMatches = []
    for (var match of matches) {
      if (match.WonPlayerIndex < 1 && userInfo != null && (userInfo.UserID == match.Player1ID || userInfo.UserID == match.Player2ID)) {
        setMePlaying(true)
      }

      receivedMatches.push({
        "id": match.MatchID,
        "tournamentRoundText": match.RoundNum,
        "nextMatchId": match.NextMatchID,
        "state": match.WonPlayerIndex > 0 ? 'DONE' : "NO_SHOW",
        "participants": [
          {
            "id": match.Player1ID, // Unique identifier of any kind
            "resultText": match.ScorePlayer1, // Any string works
            "isWinner": match.WonPlayerIndex == 1,
            "status": match.WonPlayerIndex > 0 ? 'PLAYED' : null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
            "name": match.Player1Nickname == null ? "TBD" : <a href={"/user/"+match.Player1Nickname} style={{color: "inherit", textDecoration: "none"}} target="_blank">{match.Player1Nickname}</a>
          },
          {
            "id": match.Player2ID,
            "resultText": match.ScorePlayer2,
            "isWinner": match.WonPlayerIndex == 2,
            "status": match.WonPlayerIndex > 0 ? 'PLAYED' : null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY'
            "name": match.Player2Nickname == null ? "TBD" : <a href={"/user/"+match.Player2Nickname} style={{color: "inherit", textDecoration: "none"}} target="_blank">{match.Player2Nickname}</a>
          }
        ]
      })
    }
    setMatches(receivedMatches)
  }

  useEffect(() => {
      if (!props.tournament) {
          return
      }
      TournamentsService.getBrackets(props.tournament.ID).then((response)=>{
        if (response.data.Success == SuccessMessage) { 
          makeBracketsMatchesData(response.data.Matches)
        }
      })
  }, [props.tournament])

  useEffect(() => {
    if (!props.tournament) {
        return
    }
    sendMsgSite(siteWS.ws, "WatchTournament", {TournamentID: props.tournament.ID})
  }, [props.tournament, siteWS.ws])

  useEffect(() => {
    if (bracketsMatches.matches.length > 0) {
      makeBracketsMatchesData(bracketsMatches.matches)
    }
  }, [bracketsMatches])

  const goPlay = ()=>{
    navigate(linkToGameByID(props.tournament.GameIDs[0]))
  }

  const size = useWindowSize();
  const finalWidth = Math.max(size.width - 50, 500);
  const finalHeight = Math.max(size.height - 240, 500);
    return (
        <div>
            {
             false &&  mePlaying && <Button onClick={goPlay} variant="contained" style={{margin: "20px"}} size="large" color="success">
                <FormattedMessage id="play" />
              </Button>
            }
            {matches.length ? <SingleEliminationBracket
                matches={matches.length ? matches : matchesTemplate}
                matchComponent={Match}
                theme={theme.palette.mode === 'dark' ? undefined : WhiteTheme}
                svgWrapper={({ children, ...props }) => (
                <SVGViewer width={finalWidth} height={finalHeight} {...props}
                  background={theme.palette.mode === 'dark' ? "#121212" : "#FFFFFF"}
                  SVGBackground={theme.palette.mode === 'dark' ? "#121212" : "#FFFFFF"}   
                  style={{cursor: "grab"}}             
                >
                    {children}
                </SVGViewer>
                )}
            /> : <div style={{margin: "20px"}}>
              <FormattedMessage id="has_no_brackets" />
            </div>}
        </div>
    )
}

export default Brackets;